import {BarcodePrefix} from "@/enums/BarcodePrefix";
import {trimStart} from "lodash";

export function isPackageIdentifier(code: string): boolean {
    const regex = /^PI[A-Z\d]{10}$/;
    return code.match(regex) !== null;
}
export function isContainer(code: string): boolean {
    return ['CAS', 'PAL'].includes(code.substring(0, 3));
}

export const getPackageContainer = (scanResult: string): string|undefined => {
    try {
        if(scanResult.startsWith(BarcodePrefix.PACKAGE_CONTAINER)) {
            return trimStart(scanResult, BarcodePrefix.PACKAGE_CONTAINER);
        }
    } catch (e) {
        console.log('Error scanning barcode', e);
    }
}
import Login from '@/components/pages/Login'
import HubSelector from '@/components/pages/HubSelector'
import Home from '@/components/pages/Home'
import Orders from '@/components/pages/Orders'
import Picking from '@/components/pages/Picking'
import i18n from '@/plugins/i18n'
import store from '@/bootstrap/store'
import Settings from "@/components/pages/Settings";
import StartPickFromBarcode from '@/components/pages/StartPickFromBarcode'
import StartNextOrder from "../components/pages/StartNextOrder";
import Logout from "@/components/pages/Logout";
import StartManualCount from "@/components/pages/CycleCounting/StartManualCount.vue";
import ProductCount from "@/components/pages/CycleCounting/ProductCount.vue";
import CountSummary from '@/components/pages/CycleCounting/CountSummary.vue';
import CountTasks from '@/components/pages/CycleCounting/CountTasks.vue'
import WavePickStart from '@/components/pages/WavePicking/WavePickStart.vue';
import WavePickTasks from '@/components/pages/WavePicking/WavePickTasks.vue';
import WavePickTotes from "@/components/pages/WavePicking/WavePickTotes.vue";
import WavePickComplete from "@/components/pages/WavePicking/WavePickComplete.vue";
import {PERMISSION_WAVE_PICK} from "@/bootstrap/config";
import WavePickAbandon from "@/components/pages/WavePicking/WavePickAbandon.vue";
import outboundRoutes from "@/routes/outbound";

const canWavePick = (to, from, next) => {
    if (store.getters['core/userHasPermission'](PERMISSION_WAVE_PICK)) {
        next()
    } else {
        next(false)
    }
}

export default [
    {
        name: 'login',
        path: '/login',
        component: Login,
        meta: {
            title() {
                return i18n.t('login')
            }
        }
    },
    {
        name: 'logout',
        path: '/logout',
        component: Logout
    },
    {
        name: 'hub_selector',
        path: '/select-hub',
        component: HubSelector,
        meta: {
            title() {
                return i18n.t('select_site_hub')
            }
        }
    },
    {
        name: 'home',
        path: '/',
        component: Home,
        meta: {
            title() {
                return i18n.t('home')
            }
        }
    },
    {
        name: 'orders',
        path: '/orders',
        component: Orders,
        meta: {
            title() {
                return i18n.t('orders_hub_id', {hub_id: store.state.core.hubId})
            }
        }
    },
    {
        name: 'order',
        path: '/order/:orderId',
        component: Picking,
        props: true,
        meta: {
            title() {
                return i18n.t('order')
            }
        }
    },
    {
        name: 'start-next-order',
        path: '/start-next-order',
        component: StartNextOrder,
        props: false,
        meta: {
            title() {
                return i18n.t('loading_next_order')
            }
        }
    },
    {
        name: 'start-pick-with-barcode',
        path: '/hub/:hub_id/start_pick_pick_with_barcode',
        component: StartPickFromBarcode,
        meta: {
            title: function () {
                return i18n.t('start_pick_with_barcode_title', {hub_id: store.state.core.hubId})
            }
        }
    },
    {
        name: 'settings',
        path: '/settings',
        component: Settings,
        props: true,
        meta: {
            title() {
                return i18n.t('settings')
            }
        }
    },
    {
        name: 'count_tasks',
        path: '/count-tasks',
        component: CountTasks,
        meta: {
            title() {
                return i18n.t('stock_count');
            }
        }
    },
    {
        name: 'start_manual_count',
        path: '/start-manual-count',
        component: StartManualCount,
        meta: {
            title() {
                return i18n.t('cycle_counting.manual_count');
            }
        }
    },
    {
        name: 'product_count',
        path: '/product-count',
        component: ProductCount,
        meta: {
            title() {
                return i18n.t('cycle_counting.count_task');
            }
        }
    },
    {
        name: 'count_summary',
        path: '/count-summary',
        component: CountSummary,
        meta: {
            title() {
                return i18n.t('summary');
            }
        }
    },
    {
        name: 'wave_pick_start',
        path: '/wave-pick-start',
        component: WavePickStart,
        meta: {
            title() {
                return i18n.t('wave_picking.wave_picking');
            }
        },
        beforeEnter: canWavePick,
    },
    {
        name: 'wave_pick_tasks',
        path: '/wave-pick-tasks',
        component: WavePickTasks,
        meta: {
            title() {
                return i18n.t('wave_picking.wave_picking');
            }
        },
        beforeEnter: canWavePick,
    },
    {
        name: 'wave_pick_totes',
        path: '/wave-pick-totes/:sku',
        component: WavePickTotes,
        meta: {
            title() {
                return i18n.t('wave_picking.wave_picking');
            }
        },
        beforeEnter: canWavePick,
    },
    {
        name: 'wave_pick_complete',
        path: '/wave-pick-complete',
        component: WavePickComplete,
        meta: {
            title() {
                return i18n.t('wave_picking.wave_picking');
            }
        },
        beforeEnter: canWavePick,
    },
    {
        name: 'wave_pick_abandon',
        path: '/wave-pick-abandon/:sku/:reason',
        component: WavePickAbandon,
        meta: {
            title() {
                return i18n.t('wave_picking.wave_picking');
            }
        },
        beforeEnter: canWavePick,
    },
    ...outboundRoutes,
]

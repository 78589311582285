export enum PackageContainerStatus {
	NEW = 'NEW',
	PROCESSING = 'PROCESSING',
  DISPATCHED = 'DISPATCHED',
}

export enum PackageContainerType {
	YORK = 'YORK',
  STATIC = 'STATIC'
}

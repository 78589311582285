import {defineStore} from "pinia";
import {ref} from "vue";

export type Carriers = {
  id: string,
  type: 'carrier',
  attributes: {
    name: string,
  }
}

export const useCarriersStore = defineStore('CarriersStore', () => {
    const carriers = ref<Carriers[]>([]);

    const setCarriers = (newCarriers: Carriers[]) => {
      carriers.value = newCarriers
    }

    return {
        carriers,
        setCarriers,
    }
})

import { z } from "zod";

const packageContainerRelationshipSchema = z.object({
  id: z.string(),
  type: z.literal('package-container'),
}).optional();

export const GetPackageResponseSchema = z.object({
  data: z.object({
    id: z.string(),
    type: z.literal("package"),
    attributes: z.object({
      tracking_id: z.string(),
      tracking_code: z.string(),
      carrier: z.string(),
      service: z.string(),
      package_container_id: z.number().int().nullable(),
      order_id: z.number().int(),
    }),
    relationships: z.object({
      package_container: z.object({
        data: packageContainerRelationshipSchema.nullable()
      }),
    }).optional()
  }),
  included: z.array(
    z.object({
        type: z.literal("package-container"),
        id: z.string(),
        attributes: z.object({
          name: z.string(),
          status: z.string(),
        }),
      })
  ).optional()
});

export type GetPackageResponseType = z.infer<typeof GetPackageResponseSchema>;

import {api} from "@/bootstrap/api";
import store from "@/bootstrap/store";
import moment from "moment";
import {AuditLogEventTypes} from "@/enums/AuditLogEventTypes";
import {OrderInProgressConfirmationActions} from "@/enums/OrderInProgressConfirmationActions";
import {Route} from "vue-router/types/router";
import {datadogLogs} from "@datadog/browser-logs";
import {Batch} from "@/types/Batch";
import {Stock} from "@/types/Stock";

type DataPayload = {
  [key: string]: object | string | number | boolean | null | undefined;
};

type AuditLogEntry = {
  user_id: number | null;
  event: AuditLogEventTypes;
  data: DataPayload;
  created_at: string;
};

export default class AuditLog {
  enabled: boolean;
  logs: AuditLogEntry[] = [];

  constructor(enabled = true) {
    this.enabled = enabled;
  }

  add(event: AuditLogEventTypes, data: DataPayload = {}) {
    try {
      this.validateData(data);
    } catch (e) {
      // In the event the audit data is invalid then log to DataDog and omit the entire entry.
      if (e instanceof Error) {
        console.error(e.message);
        datadogLogs.logger.error(`Omitting invalid audit entry data payload`, {
          exception: e,
          data,
        });
        return;
      }
    }
    const user_id = store.getters["core/getAuthUserId"];
    this.logs.push({
      user_id,
      event,
      data,
      created_at: moment().toISOString(),
    });
  }

  async commit() {
    if (!this.enabled) {
      this.logs = [];
      return;
    }
    if (this.logs.length === 0) {
      return;
    }
    this.logs.forEach((logEntry: AuditLogEntry) => {
      datadogLogs.logger.info(logEntry.event, logEntry);
    });
    try {
      await api("auditLog", {
        data: this.logs,
      });
    } catch (e) {
      datadogLogs.logger.error("Error posting audit logs", { exception: e });
    }
    this.logs = [];
  }

  // User signs in - record site, hub, user id - date & time
  logSignIn() {
    this.add(AuditLogEventTypes.EVENT_SIGN_IN);
    this.commit();
  }

  // User changes hub - which hub and time
  logHubChange(hubId: number) {
    this.add(AuditLogEventTypes.EVENT_HUB_CHANGE, {
      hub_id: hubId,
    });
    this.commit();
  }

  // User selects ‘pick’ an order
  logStartPicking() {
    this.add(AuditLogEventTypes.EVENT_START_PICKING, {
      order_id: store.getters["picking/getOrderId"],
      hub_id: store.state.core.hubId,
    });
  }
  // Response to the "Order in progress" dialog on the picking screen
  logOrderInProgressDialogResponse(
    response: OrderInProgressConfirmationActions
  ) {
    this.add(AuditLogEventTypes.EVENT_ORDER_IN_PROGRESS_RESPONSE, {
      order_id: store.getters["picking/getOrderId"],
      hub_id: store.state.core.hubId,
      response,
    });
  }

  // User scans item
  logItemScan(barcode: string|null, sku?: number | null, batchId?: number | null) {
    this.add(AuditLogEventTypes.EVENT_ITEM_SCAN, {
      order_id: store.getters["picking/getOrderId"],
      barcode: barcode,
      hub_id: store.state.core.hubId,
      sku,
      batchId,
    });
  }

  // If no barcode - log reason why
  logManualPick(sku: number, reason: string) {
    this.add(AuditLogEventTypes.EVENT_MANUAL_PICK, {
      sku: sku,
      reason: reason,
      order_id: store.getters["picking/getOrderId"],
    });
  }

  // Container scanned for B2B
  logContainerScan(containerLabel: string, stock: Stock[]) {
    this.add(AuditLogEventTypes.EVENT_CONTAINER_SCAN, {
      hub_id: store.state.core.hubId,
      order_id: store.getters["picking/getOrderId"],
      containerLabel,
      stock
    });
  }

  // If incorrect item scanned for that order  - store the sku of the incorrect item
  logMispick(barcode: string, sku: number | null, batchId?: number | null) {
    this.add(AuditLogEventTypes.EVENT_MISPICK, {
      barcode: barcode,
      order_id: store.getters["picking/getOrderId"],
      hub_id: store.state.core.hubId,
      sku,
      batchId,
    });
  }

  // If item that does not meet minimum batch expiry date  - store the sku and batch ID of the incorrect scan
  logInvalidBatchScan(barcode: string, batchId: number) {
    this.add(AuditLogEventTypes.EVENT_BATCH_INVALID_SCAN, {
      barcode: barcode,
      order_id: store.getters["picking/getOrderId"],
      hub_id: store.state.core.hubId,
      batchId,
    });
  }

  // If batch has been recalled
  logRecalledBatchScan(barcode: string, batch: Batch) {
    this.add(AuditLogEventTypes.EVENT_BATCH_RECALLED_SCAN, {
      barcode: barcode,
      order_id: store.getters["picking/getOrderId"],
      hub_id: store.state.core.hubId,
      batch_id: batch.batch_id,
      recall_status: batch.recall_status,
    });
  }

  // If scan does not contain batch id for a sku marked as batch
  logMissingBatchAssociation(barcode: string, sku: number | null) {
    this.add(AuditLogEventTypes.EVENT_MISSING_BATCH_ASSOCIATION, {
      barcode,
      order_id: store.getters["picking/getOrderId"],
      hub_id: store.state.core.hubId,
      sku,
    });
  }

  // If single item order and user overrides suggested packaging - what should be & what choice of package
  logChangedPackaging(before: string, after: string) {
    this.add(AuditLogEventTypes.EVENT_PACKAGING_CHANGE, {
      order_id: store.getters["picking/getOrderId"],
      before: before,
      after: after,
    });
  }

  // When packing complete (submit / submit and next order)
  logCompletePacking(
    selectedPackageType: string,
    packageIdentifier: string,
    redirectionStrategy: string
  ) {
    this.add(AuditLogEventTypes.EVENT_COMPLETE_PACKING, {
      selectedPackageType,
      packageIdentifier,
      redirectionStrategy,
    });
  }

  // When packing started (enter the packing screen)
  logStartPacking(order: DataPayload) {
    this.add(AuditLogEventTypes.EVENT_START_PACKING, order);
  }

  // If the scanned barcode cannot be found in start_pick_from_barcode
  logFailedToFindOrder(barcode: string) {
    this.add(AuditLogEventTypes.EVENT_FAIL_TO_FIND_ORDER, {
      order_id: barcode,
      hub_id: store.state.core.hubId,
    });
  }

  // When route changes, allowing us to track an entire user journey throughout the application
  logRouteChange(from: Route, to: Route) {
    this.add(AuditLogEventTypes.EVENT_ROUTE_CHANGE, {
      from_name: from.name,
      from_params: from.params,
      from_path: from.path,
      to_name: to.name,
      to_params: to.params,
      to_path: to.path,
    });
  }

  logPILabelAssociation(packageIdentifier: string) {
    this.add(AuditLogEventTypes.EVENT_PI_LABEL_ASSOCIATION, {
      package_identifier: packageIdentifier,
      order_id: store.getters["picking/getOrderId"],
    });
  }

  // When user stops picking we want record the reason
  logAbandonOrder(abandonmentReason: string, sku?: number | null) {
    this.add(AuditLogEventTypes.EVENT_ABANDON_ORDER, {
      order_id: store.getters["picking/getOrderId"],
      abandonment_reasons: abandonmentReason,
      sku,
    });
  }

  packageContainerScanned(id: string, containerType: string): void {
    this.add(AuditLogEventTypes.EVENT_PACKAGE_CONTAINER_SCANNED, {
      id,
      containerType
    })
  }

  invalidPackageContainerScanned(id: string, error: string): void {
    this.add(AuditLogEventTypes.EVENT_INVALID_PACKAGE_CONTAINER_SCANNED, { id, error })
  }

  moveBetweenPackageContainerStarted(): void {
    this.add(AuditLogEventTypes.EVENT_TRANSFER_PACKAGE_BETWEEN_CONTAINERS_STARTED);
  }

  moveBetweenPackageContainerComplete(source: string, target: string): void {
    this.add(AuditLogEventTypes.EVENT_TRANSFER_PACKAGE_BETWEEN_CONTAINERS_FINISHED, {
      source,
      target,
    });
    this.commit();
  }

  moveBetweenPackageContainerWarning(id: string, error: string): void {
    this.add(AuditLogEventTypes.EVENT_TRANSFER_PACKAGE_BETWEEN_CONTAINERS_WARNING, { id, error });
  }


  // cannot change as we dont know order_id at this point
  assignPackagesToPackageContainerStarted(scan: string): void {
    this.add(AuditLogEventTypes.EVENT_ASSIGN_PACKAGES_TO_PACKAGE_CONTAINERS_STARTED, { scanned: scan});
  }
  assignPackagesToPackageContainerComplete(
    packages: {tracking_code: string; order_id: number }[],
    target: string
  ): void {
    packages.forEach((pkg) => {
      this.add(AuditLogEventTypes.EVENT_ASSIGN_PACKAGES_TO_PACKAGE_CONTAINERS_FINISHED, {
        target,
        tracking_code: pkg.tracking_code,
        order_id: pkg.order_id,
      });
    });
    this.commit();
  }

  packageToPackageContainerWarning(id: string, error: string): void {
    this.add(AuditLogEventTypes.EVENT_ASSIGN_PACKAGES_TO_PACKAGE_CONTAINERS_WARNING, {
      tracking_code: id,
      error
    });
  }

  packageToPackageContainerScanned(id: string): void {
    this.add(AuditLogEventTypes.EVENT_ASSIGN_PACKAGES_TO_PACKAGE_SCANNED, {
      tracking_code: id
    });
  }

  /**
   * @param data Audit log event data to validate.
   * @param ancestors List of property names that precede a nested object, providing a full path if required.
   * @private
   */
  private validateData(data: DataPayload, ancestors: string[] = []) {
    for (const key in data) this.validateDataElement(key, ancestors, data);
  }

  // Validate a single property in an audit log event data.
  private validateDataElement(
    key: string,
    ancestors: string[] = [],
    data: DataPayload
  ) {
    // Can never log audit data with an empty key (MongoDB limitation).
    if (!key) {
      throw Error(
        `Empty key found ${
          ancestors.length > 0 ? `in "${ancestors.join(".")}"` : "on top level"
        } object`
      );
    }
    // Validate the properties of a nested object too.
    if (typeof data[key] === "object") {
      // Push current parent to build up an array of ancestors, so we know the full path to the nested property
      ancestors.push(key);
      this.validateData(data[key] as DataPayload, ancestors);
    }
  }
}

import { api } from "@/bootstrap/api";
import { GetCarrierListResponseSchema } from "@/api/responses/getCarrierListResponse";
import { GetPackageContainerResponseSchema, GetPackageContainerResponseType } from "@/api/responses/getPackageContainerResponse";
import { GetPackageResponseSchema, GetPackageResponseType } from './responses/getPackageResponse';
import { ZodError } from 'zod';

type Container = {
  type: 'package-container',
  id: string
}

export type GetResponseParseSafeType<T> =
  { success: true; data: T } |
  { success: false; error: ZodError };

export type CreateCarrierCollectionRequestType = {
  data: {
    type: 'carrier-collection',
    attributes: {
      carrier_id: string;
      collection_reference: string;
    },
    relationships: {
      packageContainers: {
        data: Container[]
      }
    }
  }
}

export type MovePackageContainerRequestType = {
  data: {
    type: 'package-container',
    id: number,
  }
}

export type AssignToPackagesContainerType = {
  data: Array<{
    type: 'package',
    attributes: {
      tracking_code: string
    }
  }>
}

export default {
    async dispatchToCarrier(containerIds: number[], carrierId: string, collectionRef: string) {
        const packageContainers: Container[] = containerIds.map(containerId => ({
            type: "package-container",
            id: String(containerId)
        }));
        const payload: CreateCarrierCollectionRequestType = {
          data: {
            type: "carrier-collection",
            attributes: {
              carrier_id: carrierId,
              collection_reference: collectionRef
            },
            relationships: {
              packageContainers: {
                data: packageContainers
              }
            }
          }
        };

        return api(
            'dispatchToCarrier',
            {
                data: payload,
            }
        )
      // todo:: audit log
    },

    async transferPackageContainer(payload: { sourceContainer: string, targetContainer: string }) {
      const data: MovePackageContainerRequestType = {
        data: {
          type: "package-container",
          id: Number(payload.sourceContainer)
        }
      };

      return api(
        'transferToPackageContainer',
        {
          data: data,
          inlineParams: {
            packageContainer: payload.targetContainer
          }
        }
      )
    },

    async assignToPackageContainer(payload: { packages: string[], targetContainer: string }) {
      const data: AssignToPackagesContainerType = {data:
        payload.packages.map(pkg => {
          return {
            type: "package",
            attributes: {
                tracking_code: pkg,
            }
          }
        })
      }

      return api(
        'assignToPackageContainer',
        {
          data: data,
          inlineParams: {
            package_container: payload.targetContainer
          }
        }
      )
    },

    async getCarrierList(siteId: number) {
        const response = await api(
          'getCarrierList',
          {
              params: {
                  'filter[site_id]': siteId
              },
          }
        );

        return GetCarrierListResponseSchema.parse(response.data.data);
    },

    async getPackageContainer(packageContainerId: string): Promise<GetResponseParseSafeType<GetPackageContainerResponseType>> {
      const response = await api(
        'getPackageContainer',
        {
          inlineParams: {
            packageContainerId: packageContainerId
          },
        }
      );

      return GetPackageContainerResponseSchema.safeParse(response.data);
    },

    async getPackage(trackingCode: string): Promise<GetResponseParseSafeType<GetPackageResponseType>> {
      const response = await api(
        'getPackage',
        {
          params: {
              'filter[tracking_code]': trackingCode
          },
        }
      );
      return GetPackageResponseSchema.safeParse(response.data);
    }
}

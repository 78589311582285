import { z } from "zod";

export const GetCarrierListResponseSchema = z.object({
  id: z.string(),
  type: z.literal("carrier"),
  attributes: z.object({
    name: z.string(),
  })
}).array();


// extract the inferred type
export type GetCarrierListResponseType = z.infer<typeof GetCarrierListResponseSchema>;

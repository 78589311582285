import { z } from "zod";

const packagesRelationshipSchema = z.object({
  id: z.string(),
  type: z.literal('package'),
});

const carrierCollectionRelationshipSchema = z.object({
  id: z.string(),
  type: z.literal('carrier-collection'),
}).optional();

export const GetPackageContainerResponseSchema = z.object({
  data: z.object({
    id: z.string(),
    type: z.literal("package-container"),
    attributes: z.object({
      carrier_collection_id: z.number().nullable(),
      name: z.string(),
      status: z.string(),
      type: z.string(),
    }),
    relationships: z.object({
      packages: z.object({
        data: z.array(packagesRelationshipSchema)
      }),
      carrier_collection: z.object({
        data: carrierCollectionRelationshipSchema.nullable()
      }),
    }).optional()
  })
});


// extract the inferred type
export type GetPackageContainerResponseType = z.infer<typeof GetPackageContainerResponseSchema>;

import i18n from "@/plugins/i18n";
import OutboundHome from "@/components/pages/Outbound/OutboundHome.vue";
import DispatchContainers from '@/components/pages/Outbound/DispatchContainers.vue';
import MoveBetweenContainers  from "@/components/pages/Outbound/MoveBetweenContainers.vue";

const outboundRoutes = [
  {
    name: 'outbound_home',
    path: '/outbound',
    component: OutboundHome,
    meta: {
      title() {
        return i18n.t('outbound.outbound_title');
      }
    },
  },
  {
    name: 'dispatch_to_carrier',
    path: '/dispatch-to-carrier',
    component: DispatchContainers,
    meta: {
      title() {
        return i18n.t('outbound.outbound_title');
      }
    },
  },
  {
    name: 'move_between_containers',
    path: '/move-between-containers',
    component: MoveBetweenContainers,
    meta: {
      title() {
        return i18n.t('outbound.outbound_title');
      }
    },
  },
];

export default outboundRoutes;

<template>
  <v-list nav dense>
    <v-list-item-group
        v-model="group"
        active-class="primary--text"
    >
      <template v-for="(item, idx) in items">
        <v-list-item :to="{name: item.routeName}" :key="idx" v-if="!item.hidden">
          <v-list-item-icon>
            <v-icon>
              {{ item.icon }}
            </v-icon>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>
              {{ item.label }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>

      <v-list-item>
        <v-list-item-icon>
          <v-icon>
            mdi-logout
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title @click="logout" id="btn_logout">
            {{ $t('logout') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list-item-group>
  </v-list>
</template>

<script>

import {PERMISSION_WAVE_PICK} from "@/bootstrap/config";

export default {
  name: 'Navigation',
  data: () => ({
    group: null,
  }),
  computed: {
    isWavePickEnabled() {
      return this.$store.getters['core/userHasPermission'](PERMISSION_WAVE_PICK)
    },
    items() {
      return [
        { icon: 'mdi-package-variant', label: this.$t('orders'), routeName: 'orders', hidden: false },
        { icon: 'mdi-waves', label: this.$t('wave_picking.wave_picking'), routeName: 'wave_pick_start', hidden: !this.isWavePickEnabled },
        { icon: 'mdi-home-city-outline', label: this.$t('change_hub'), routeName: 'hub_selector', hidden: false },
        { icon: 'mdi-numeric-2-box-multiple-outline', label: this.$t('cycle_counting.stock_count'), routeName: 'count_tasks', hidden: false },
        { icon: 'mdi-truck-delivery', label: this.$t('outbound.outbound_menu_item'), routeName: 'outbound_home', hidden: false },
        { icon: 'mdi-cog', label: this.$t('settings'), routeName: 'settings', hidden: false }
      ]
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('core/redirect', { name: 'logout' })
    },
  },
}
</script>

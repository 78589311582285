import {API_BASE_URL, API_CLIENT_ID, PERFECT_PICKING_API_URL} from '@/bootstrap/config'

export default {
    client_id: API_CLIENT_ID,
    endpoints: {
        accessToken: {
            method: 'post',
            url: '/api/v1/oauth-proxy/token/password',
            data: {
                grant_type: 'password',
                scope: ''
            },
            base_url: API_BASE_URL
        },
        refreshToken: {
            method: 'post',
            url: '/api/v1/oauth-proxy/token/refresh-token',
            data: {
                grant_type: 'refresh_token',
                scope: ''
            },
            base_url: API_BASE_URL
        },
        authUser: {
            method: 'get',
            url: '/api/v1/user',
            base_url: API_BASE_URL
        },
        sites: {
            method: 'get',
            url: '/api/v1/user/{user_id}/sites',
            base_url: API_BASE_URL
        },
        hubs: {
            method: 'get',
            url: '/api/v1/user/{user_id}/site/{site_id}/hubs',
            base_url: API_BASE_URL
        },
        allocatedOrders: {
            method: 'get',
            url: '/v1/orders/allocated',
            base_url: PERFECT_PICKING_API_URL
        },
        ordersOverview: {
            method: 'get',
            url: '/v1/orders/allocated/overview',
            base_url: PERFECT_PICKING_API_URL
        },
        ordersFilters: {
            method: 'get',
            url: '/api/v1/order/allocated/filters',
            base_url: API_BASE_URL
        },
        order: {
            method: 'get',
            url: '/v1/orders/{order_id}',
            base_url: PERFECT_PICKING_API_URL
        },
        smartPickOrders: {
          method: 'post',
          url: '/v1/orders/actions/start-smart-pick',
          base_url: PERFECT_PICKING_API_URL
        },
        remoteOrderId: {
            method: 'get',
            url: '/api/v1/order/remote-order-id/{remote_order_id}',
            base_url: API_BASE_URL
        },
        handleUnfoundRemoteOrderId: {
            method: 'post',
            url: '/api/v1/order/handle-unfound/{remote_order_id}',
            base_url: API_BASE_URL
        },
        startOrder: {
            method: 'post',
            url: '/v1/orders/{order_id}/start',
            base_url: PERFECT_PICKING_API_URL
        },
        abandonOrder: {
            method: 'post',
            url: '/v1/orders/{order_id}/abandon',
            base_url: PERFECT_PICKING_API_URL
        },
        pickOrder: {
            method: 'post',
            url: '/api/v1/order/{order_id}/pick',
            base_url: API_BASE_URL
        },
        logout: {
            method: 'get',
            url: '/api/v1/user/logout',
            base_url: API_BASE_URL
        },
        packageTypes: {
            method: 'get',
            url: '/v1/container-types/all',
            base_url: PERFECT_PICKING_API_URL
        },
        insertPrint: {
            method: 'post',
            url: '/api/v1/order/{order_id}/insert/print',
            base_url: API_BASE_URL
        },
        patchOrder: {
            method: 'patch',
            url: '/v1/orders/{order_id}',
            base_url: PERFECT_PICKING_API_URL
        },
        updatePackageIdentifier: {
            method: 'post',
            url: '/api/v1/order/{order_id}/package-identifier',
            base_url: API_BASE_URL
        },
        nextOrder: {
            method: 'post',
            url: '/v1/orders/start-next-pick',
            base_url: PERFECT_PICKING_API_URL
        },
        auditLog: {
            method: 'post',
            url: '/api/v1/audit-log/bulk',
            base_url: API_BASE_URL
        },
        containerStock: {
            method: 'get',
            url: '/v1/containers/labels/{labelContent}/stock',
            base_url: PERFECT_PICKING_API_URL
        },
        getHubLocations: {
            method: 'get',
            url: '/v1/hubs/{hubNumber}/locations/{locationName}',
            base_url: PERFECT_PICKING_API_URL
        },
        storeStockCounts: {
          method: 'post',
          url: '/v1/stock-counts',
          base_url: PERFECT_PICKING_API_URL
        },
        getStockForLocation: {
          method: 'get',
          url: '/v1/stock',
          base_url: PERFECT_PICKING_API_URL
        },
        getStockCountTasks: {
          method: 'get',
          url: '/v1/stock-count-tasks',
          base_url: PERFECT_PICKING_API_URL
        },
        updateTaskStatus: {
          method: 'patch',
          url: '/v1/stock-count-tasks/{taskId}',
          base_url: PERFECT_PICKING_API_URL
        },
        getProducts: {
            method: 'get',
            url: '/v1/products',
            base_url: PERFECT_PICKING_API_URL
        },
        dispatchToCarrier: {
            method: 'post',
            url: '/v1/carrier-collection/dispatch',
            base_url: PERFECT_PICKING_API_URL
        },
        getCarrierList: {
            method: 'get',
            url: '/api/v1/site-carriers',
            base_url: API_BASE_URL
        },
        transferToPackageContainer: {
            method: 'post',
            url: '/v1/package-container/{packageContainer}/transfer',
            base_url: PERFECT_PICKING_API_URL
        },
        assignToPackageContainer: {
            method: 'post',
            url: '/v1/package-container/{package_container}/assign',
            base_url: PERFECT_PICKING_API_URL
        },
        getPackageContainer: {
            method: 'get',
            url: '/v1/package-container/{packageContainerId}',
            base_url: PERFECT_PICKING_API_URL
        },
        getPackage: {
          method: 'get',
          url: '/v1/packages',
          base_url: PERFECT_PICKING_API_URL
        }
    }
}

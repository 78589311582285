export enum AuditLogEventTypes {
  EVENT_SIGN_IN = "sign in",
  EVENT_HUB_CHANGE = "hub change",
  EVENT_START_PICKING = "start picking",
  EVENT_MISPICK = "mispick",
  EVENT_BATCH_INVALID_SCAN = "batch invalid scan",
  EVENT_BATCH_RECALLED_SCAN = "batch recalled scan",
  EVENT_MANUAL_PICK = "manual pick",
  EVENT_CONTAINER_SCAN = "container scan",
  EVENT_PACKAGING_CHANGE = "packaging change",
  EVENT_ITEM_SCAN = "item scan",
  EVENT_FAIL_TO_FIND_ORDER = "failed to find order",
  EVENT_COMPLETE_PACKING = "packing complete",
  EVENT_START_PACKING = "packing started",
  EVENT_ORDER_IN_PROGRESS_RESPONSE = "order in progress response",
  EVENT_PI_LABEL_ASSOCIATION = "pi label associated with order",
  EVENT_ROUTE_CHANGE = "route change",
  EVENT_ABANDON_ORDER = "abandon order",
  EVENT_MISSING_BATCH_ASSOCIATION = "missing batch association",

  EVENT_TRANSFER_PACKAGE_BETWEEN_CONTAINERS_STARTED = "Transfer packages between containers started",
  EVENT_TRANSFER_PACKAGE_BETWEEN_CONTAINERS_FINISHED = "Transfer packages between containers finished",
  EVENT_TRANSFER_PACKAGE_BETWEEN_CONTAINERS_WARNING = "Transfer packages between containers warning",
  EVENT_PACKAGE_CONTAINER_SCANNED = "Package container scanned",
  EVENT_INVALID_PACKAGE_CONTAINER_SCANNED = "Invalid package container scanned",

  EVENT_ASSIGN_PACKAGES_TO_PACKAGE_CONTAINERS_STARTED = "Assign packages to containers started",
  EVENT_ASSIGN_PACKAGES_TO_PACKAGE_CONTAINERS_FINISHED = "Assign packages to containers finished",
  EVENT_ASSIGN_PACKAGES_TO_PACKAGE_CONTAINERS_WARNING = "Assign packages to containers warning",
  EVENT_ASSIGN_PACKAGES_TO_PACKAGE_SCANNED = "Assign packages to containers scanned",
}
